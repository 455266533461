import App, { AppProps, AppContext } from 'next/app'
import Script from 'next/script'
import ErrorPage from 'next/error'
import { useRouter } from 'next/router'
import { NextIntlProvider } from 'next-intl'
import { ChakraProvider, SlideFade } from '@chakra-ui/react'
import NextProgress from 'next-progress'

import '../styles/globals.css'
import '../styles/article.css'
import '../styles/subscribe.css'
import theme from '../chakraTheme'
import Fonts from '../chakraTheme/fonts'
import { DashboardLayout } from '@/layouts/DashboardLayout'
import { DefaultLayout } from '@/layouts/DefaultLayout'
import { CookiesDisclaimer } from '@/components/CookiesDisclaimer'
import SeoHead from '@/components/seo-head'

import { getGlobalData } from '@/api/strapi/globalData'
import { globalDataPlaceholders } from 'data/globalDataPlaceholders'
import { initializeApollo } from '@/utils/apolloClient'
import { AuthorizedApolloProvider } from '@/hooks/useAuthContext'
import { usePageViewTracking } from '@/hooks/usePageViewTracking'

function CompassApp({ Component, pageProps }: AppProps) {
  const { route, pathname } = useRouter()
  usePageViewTracking()

  // @ts-ignore
  const { global, isHomePage, seo } = pageProps

  if (global == null) {
    return (
      <ErrorPage
        statusCode={500}
        title={'Site is under maintenance. Please try again later'}
      />
    )
  }

  const renderLayout = () => {
    if (pathname.startsWith('/dashboard/')) {
      return (
        <DashboardLayout global={global}>
          <SlideFade key={route} offsetY={'20px'} in>
            <Component {...pageProps} />
          </SlideFade>
        </DashboardLayout>
      )
    }
    return (
      <DefaultLayout global={global} isHomePage={isHomePage}>
        <SlideFade key={route} offsetY={'20px'} in>
          <Component {...pageProps} />
        </SlideFade>
      </DefaultLayout>
    )
  }

  return (
    <>
      <AuthorizedApolloProvider pageProps={pageProps}>
        {/* Ignore "Property 'messages' does not exist on type '{}'" (it's added by next-intl package */}
        {/* @ts-ignore */}
        <NextIntlProvider messages={pageProps.messages}>
          {process.env.NODE_ENV === 'production' && (
            <>
              {/* !-- Google Tag Manager -- */}
              <Script strategy="afterInteractive" id="googletagmanager">
                {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-W57CMPGK');
              `}
              </Script>
              {/* !-- End Google Tag Manager -- */}
              {/* !-- Start of compassmininghelp Zendesk Widget script -- */}
              <Script
                strategy="afterInteractive"
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=627dbe45-61e5-4f64-947b-16b0a2a9dfba"
              />
              {/* !-- End of compassmininghelp Zendesk Widget script -- */}
            </>
          )}
          <SeoHead
            /* @ts-ignore */
            title={seo?.title}
            /* @ts-ignore */
            description={seo?.description}
            /* @ts-ignore */
            image={seo?.image}
            /* @ts-ignore */
            url={seo?.url}
          />
          <NextProgress
            delay={300}
            height={'3px'}
            color={'#f63'}
            options={{ showSpinner: false }}
          />
          <ChakraProvider theme={theme}>
            {/* Google Tag Manager (noscript) */}
            {process.env.NODE_ENV === 'production' && (
              <noscript
                dangerouslySetInnerHTML={{
                  __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W57CMPGK" height="0" width="0" style="display: none; visibility: hidden;" />`,
                }}
              />
            )}
            {/* End Google Tag Manager (noscript) */}
            <Fonts />
            {renderLayout()}
            <CookiesDisclaimer />
          </ChakraProvider>
        </NextIntlProvider>
      </AuthorizedApolloProvider>
    </>
  )
}

CompassApp.getInitialProps = async (appContext: AppContext) => {
  let globalData
  let availableLocale: string | undefined
  availableLocale = 'en'
  if (appContext.ctx.locale || appContext.ctx.defaultLocale) {
    availableLocale = appContext.ctx.locale || appContext.ctx.defaultLocale
  }

  const appProps = await App.getInitialProps(appContext)
  const apolloClient = initializeApollo()

  /**
   * Comment this code to disable fetching Strapi (if you receive some errors from CMS)
   * Start
   */
  const queryResult = await apolloClient
    .query({
      query: getGlobalData,
      variables: {
        locale: appContext.router.locale || appContext.router.defaultLocale,
      },
      context: {
        clientName: 'strapi',
        headers: {
          Authorization: '',
        },
      },
    })
    .catch(async () => {
      // if data from strapi cannot be fetched - and it's not production env -
      // return placeholders instead of navigation
      if (process.env.NODE_ENV === 'development') {
        globalData = globalDataPlaceholders
      }
    })

  if (queryResult?.data?.global?.data) {
    globalData = queryResult.data.global.data.attributes
  } else {
    if (process.env.NODE_ENV === 'production') {
      return {
        ...appProps,
        hasError: true,
      }
    }
  }
  /**
   * Comment this code to disable fetching Strapi (if you receive some errors from CMS)
   * End
   */

  return {
    ...appProps,
    pageProps: {
      messages: (await import(`../../locales/${availableLocale}.json`)).default,
      // Comment this line when you disable fetching from Strapi (if you receive some errors from CMS)
      global: globalData,
      // Uncomment this line when you disable fetching from Strapi (if you receive some errors from CMS)
      // global: globalDataPlaceholders,
    },
  }
}

export default CompassApp
