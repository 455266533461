import { Icon, IconProps } from "@chakra-ui/react"

export function ServiceIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.0583 15.1417L12.6416 10.725H11.8166L9.69995 12.8417V13.6667L14.1166 18.0833C14.4416 18.4083 14.9666 18.4083 15.2916 18.0833L17.0583 16.3167C17.3833 16 17.3833 15.4667 17.0583 15.1417Z" fill="currentColor"/>
      <path d="M13.45 8.49168L14.625 7.31668L16.3916 9.08334C17.3666 8.10834 17.3666 6.52501 16.3916 5.55001L13.4416 2.60001L12.2666 3.77501V1.42501L11.6833 0.833344L8.73329 3.78334L9.32496 4.37501H11.6833L10.5083 5.55001L11.3916 6.43334L8.98329 8.84168L5.54163 5.40001V4.21668L3.02496 1.70001L0.666626 4.05834L3.19163 6.58334H4.36663L7.80829 10.025L7.09996 10.7333H5.33329L0.916626 15.15C0.591626 15.475 0.591626 16 0.916626 16.325L2.68329 18.0917C3.00829 18.4167 3.53329 18.4167 3.85829 18.0917L8.27496 13.675V11.9083L12.5666 7.61668L13.45 8.49168Z" fill="currentColor"/>
    </Icon>
  )
}
