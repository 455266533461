import { gql } from '@apollo/client'

export const repairsByAccount = gql`
  query GetRepairsByAccount {
    repairsByAccount {
      totalCount
      nodes {
        estimatedCustomerRepairCost
        invoicedAmount
        lastFacility
        lastPositiveHashDate
        lastPositiveHashRate
        macAddress
        currentRepairCenter
        invoiceId
        minerModel
        repairClosedDate
        repairFailStatus
        repairUpdated
        serialNumber
        shortId
        ticketId
        ticketSubject
        repairTypes {
          updated
          repairsRepairTypeId
          status
          repairType
          approvedDate
          created
          currentAddress
          estimatedCompletionDate
          requestDate
        }
      }
    }
  }
`

export const getRepairsCount = gql`
  query GetRepairsCount {
    totalRequestedRepairsByAccount
  }
`

export const denyRepairMutation = gql`
  mutation DenyRepair($repairsRepairTypeId: UUID!) {
    repairsDeleteRepairsRepairType(
      input: { repairsRepairTypeId: $repairsRepairTypeId }
    ) {
      clientMutationId
      boolean  
    }
  }
`

export const approveRepairMutation = gql`
  mutation ApproveRepair($repairsRepairTypeId: UUID!) {
    repairsApproveRepairType(
      input: {repairsRepairTypeId: $repairsRepairTypeId}
    ) {
      clientMutationId
      boolean
    }
  }
`

export const approveAllRepairsMutation = gql`
  mutation ApproveAllRepairs {
    approveRepairTypesByAccount(input: {}) {
      clientMutationId
      boolean
    }
  }
`

export const denyAllRepairsMutation = gql`
  mutation DenyAllRepairs {
    deleteRepairTypesByAccount(input: {}) {
      boolean
      clientMutationId
    }
  }
`
