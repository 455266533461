import { gql } from '@apollo/client'

export const alterCart = gql`
  mutation AlterCart($productId: UUID!, $quantity: Int!) {
    alterCart(input: { productId: $productId, quantity: $quantity }) {
      boolean
    }
  }
`

export const getAllAvailableCredits = gql`
  query allCredits {
    availableCompassCredits
  }
`
export const getAllCarts = gql`
  query AllCarts {
    allCarts(orderBy: PRODUCT_NAME_ASC) {
      totalCount
      nodes {
        addressId
        cartStartTime
        cartLastTimeActive
        cartItemProductId
        cartItemQuantity
        compassScore
        facilityId
        facilityCountryCode
        facilityCountryName
        facilityDisplayName
        financingEligible
        hostedPrepaymentMonths
        hostedDepositMonths
        hostedContractLengthYears
        hostedTargetOnlineDate
        isProductTurnkey
        minerModelId
        minerModelAlgorithm
        minerModelType
        minerModelHashratePerTerahash
        minerModelName
        minerModelPowerConsumptionWatts
        nonhostedEarliestShippingDate
        nonhostedShippingTimeDescription
        marketplacePrepaymentMonths
        marketplaceDepositMonths
        monthlyHostingCharges
        paymentPlanMonths
        priceKwh
        productCondition
        productDiscountAmount
        productDiscountPercent
        productDiscountReason
        productDiscountNotes
        productListingPrice
        productName
        setupFees
        type
      }
    }
  }
`

export const getCartQuantity = gql`
  query getCartQuantity {
    allCarts {
      totalCount
      nodes {
        cartItemQuantity
      }
    }
  }
`

export const getConditionalPaymentmethods = gql`
  query getPaymentMethods(
    $isCheckout: Boolean!
    $offerId: String
    $paymentPlanSelected: Boolean
    $compassCreditsAmountUsed: Float
  ) {
    conditionalPaymentMethods(
      isCheckout: $isCheckout
      offerId: $offerId
      paymentPlanSelected: $paymentPlanSelected
      compassCreditsAmountUsed: $compassCreditsAmountUsed
    ) {
      nodes {
        methodFeePercent
        methodFeeAmount
        methodFeeText
        paymentMethod
        paymentProcessor
      }
    }
  }
`

export const getHardwareCosts = gql`
  query hardwareCosts($postalCode: String) {
    hardwareCosts(postalCode: $postalCode) {
      nodes {
        productId
        totalCost
        productCost
        taxAmount
        powerCost
        hostingPrepayment
        securityDeposit
        hostingPrepaymentSalesTax
        depositCost
        singleHostingPrepayment
        singleSecurityDeposit
      }
    }
  }
`

export const getCheckoutTotal = gql`
  query getCheckoutTotal(
    $postalCode: String
    $compassCreditsAmountUsed: Float!
    $compassWalletAmountUsed: Float!
    $paymentMethod: String
    $paymentPlanSelected: Boolean = false
    $offerId: String
  ) {
    getCheckoutTotal: getCheckoutTotal(
      postalCode: $postalCode
      compassCreditsAmountUsed: $compassCreditsAmountUsed
      compassWalletAmountUsed: $compassWalletAmountUsed
      paymentMethod: $paymentMethod
      offerId: $offerId
      paymentPlanSelected: $paymentPlanSelected
    ) {
      nodes {
        hardwareDueToday
        orderTotal
        totalHardwareCosts
        totalHostingPrepayment
        totalSecurityDeposit
        totalTaxAmount
        totalFees
        totalFinancedFees
        totalFinancedAmount
        totalFacilitySetupFee
        totalOfferAmount
      }
    }
    getCheckoutPerProduct: callCheckoutTotalPerProduct(
      postalCode: $postalCode
      paymentMethod: $paymentMethod
      offerId: $offerId
      paymentPlanSelected: $paymentPlanSelected
    ) @include(if: $paymentPlanSelected) {
      nodes {
        financedPaymentsMonthly
        hardwareCost
        hardwareDueToday
        hardwareFinancedAmount
        hardwareFinancedFeeAmount
        hardwareFinancedPercentage
        hardwareFinancingFeePercent
        paymentPlanMonths
        productId
      }
    }
  }
`

export const getOrderTotalByOrderId = gql`
  query getOrderTotal(
    $currentOrderId: UUID!
    $paymentMethod: String
    $binNumber: String
  ) {
    getOrderTotal(
      currentOrderId: $currentOrderId
      paymentMethod: $paymentMethod
      binNumber: $binNumber
    ) {
      nodes {
        orderTotal
        totalCreditsAmount
        totalFacilitySetupFees
        totalFees
        totalAmountFinanced
        totalFinancedFee
        totalHardwareCosts
        totalHardwareDueToday
        totalHostingPrepayment
        totalMonthlyFinancedPayments
        totalOfferAmount
        totalSecurityDeposit
        totalTaxAmount
      }
    }
  }
`

export const getOrderStatus = gql`
  query getOrderStatus($orderId: UUID!) {
    getOrderStatus(orderId: $orderId) {
      nodes {
        changeEvent
        orderStatus
      }
    }
  }
`

export const placeOrder = gql`
  mutation PlaceOrder(
    $address: String!
    $businessName: String
    $city: String!
    $compassCreditsAmountUsed: Float!
    $compassWalletAmountUsed: Float!
    $compassPaymentPlanSelected: Boolean!
    $country: String!
    $firstName: String!
    $lastName: String!
    $paymentMethod: String!
    $paymentPlanSelected: Boolean = false
    $postalCode: String!
    $phoneNumber: String!
    $region: String!
    $offerId: String
  ) {
    placeOrder(
      input: {
        address: $address
        businessName: $businessName
        city: $city
        compassCreditsAmountUsed: $compassCreditsAmountUsed
        compassPaymentPlanSelected: $compassPaymentPlanSelected
        compassWalletAmountUsed: $compassWalletAmountUsed
        country: $country
        firstName: $firstName
        lastName: $lastName
        paymentMethod: $paymentMethod
        paymentPlanSelected: $paymentPlanSelected
        postalCode: $postalCode
        phone: $phoneNumber
        region: $region
        offerId: $offerId
      }
    ) {
      clientMutationId
      uuid
    }
  }
`

export const getNonHostedTaxRate = gql`
  query nonHostedTaxRate($address: String!, $postalCode: String!) {
    nonhostedTaxRate(address: $address, postalCode: $postalCode)
  }
`

export const createAchOrCardPayment = gql`
  mutation createAchOrCardPayment(
    $accountNumberHash: String!
    $addressOne: String!
    $addressTwo: String!
    $binNumber: String
    $city: String!
    $country: String!
    $firstName: String!
    $fullNameHash: String!
    $lastName: String!
    $invoiceOrOrderIds: [UUID]!
    $paymentToken: String!
    $paymentMethodType: PaymentMethodType!
    $phone: String!
    $postalCode: String!
    $state: String!
  ) {
    createAchOrCardPayment(
      input: {
        accountNumberHash: $accountNumberHash
        addressOne: $addressOne
        addressTwo: $addressTwo
        binNumber: $binNumber
        city: $city
        country: $country
        firstName: $firstName
        fullNameHash: $fullNameHash
        lastName: $lastName
        invoiceOrOrderIds: $invoiceOrOrderIds
        paymentMethodType: $paymentMethodType
        paymentToken: $paymentToken
        phone: $phone
        postalCode: $postalCode
        state: $state
      }
    ) {
      clientMutationId
      string
    }
  }
`

export const processLightningOrOnchainPayment = gql`
  mutation processLightningOrOnchainPayment(
    $invoiceOrOrderIds: [UUID]!
    $lightningOrCrypto: LightningOrCrypto!
    $successUrl: String!
  ) {
    createCompassCryptoCharge(
      input: {
        invoiceOrOrderIds: $invoiceOrOrderIds
        lightningOrCrypto: $lightningOrCrypto
        successUrl: $successUrl
      }
    ) {
      results {
        chargeBtcAmount
        chargeCreatedAt
        chargeFiatValue
        chargeId
        chargeSendToAddress
        chargeStatus
        compassQrcode
      }
    }
  }
`

export const getPaymentTransactionIdByOrderId = gql`
  query getPaymentTransactionId($orderId: UUID!) {
    getPaymentTransactionId(orderId: $orderId)
  }
`

export const processCoinbasePayment = gql`
  mutation processCoinbasePayment($orderId: [UUID]!, $redirectUrl: String!) {
    createCryptoCharge(
      input: { invoiceOrOrderIds: $orderId, redirectUrl: $redirectUrl }
    ) {
      results {
        compassRedirectUrl
        chargeHostedUrl
        chargeCode
      }
    }
  }
`

export const getPaymentStatus = gql`
  query getPaymentStatus($chargeId: String, $invoiceOrOrderId: [UUID]) {
    paymentStatus(chargeId: $chargeId, invoiceOrOrderId: $invoiceOrOrderId)
  }
`

export const validatePromoCode = gql`
  query validatePromoCode($promoCode: String) {
    validatePromoCode(promoCode: $promoCode) {
      nodes {
        offerId
        offerDescription
        offerMaxRedeemableUsd
        offerRemainingRedeemableBalance
        offerExpirationDate
      }
    }
  }
`
