import { ReactiveVar, makeVar } from '@apollo/client'

type AHMFormValuesVar = {
  address: string
  businessName?: string
  city: string
  country: string
  email: string
  firstName: string
  lastName: string
  postalCode: string
  phoneNumber?: string
  region: string
  state?: string
}

export const AHMFormVariablesVar: ReactiveVar<AHMFormValuesVar> =
  makeVar<AHMFormValuesVar>({
    address: '',
    businessName: '',
    city: '',
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    phoneNumber: '',
    region: '',
    state: '',
  })

export const nonHostedTaxRateVar: ReactiveVar<number> = makeVar(0)

export const isCreditAppliedVar: ReactiveVar<boolean> = makeVar(false)

export const userCreditAppliedAmountVar: ReactiveVar<number> = makeVar(0)

export const isWalletBalanceAppliedVar: ReactiveVar<boolean> = makeVar(false)

export const userAppliedWalletAmountVar: ReactiveVar<number> = makeVar(0)

export const cartModalStateVar: ReactiveVar<boolean> = makeVar(false)

export const openCartModal: () => void = () => cartModalStateVar(true)

export const closeCartModal: () => void = () => cartModalStateVar(false)
