import { IconProps } from '@chakra-ui/react'
import { MinersIcon } from '@/components/icons/MinersIcon'
import { CompassWalletIcon } from '@/components/icons/CompassWalletIcon'
import { PaymentsIcon } from '@/components/icons/PaymentsIcon'
import { AutoRenewIcon } from '@/components/icons/AutoRenewIcon'
import { SettingsIcon } from '@/components/icons/SettingsIcon'
import { CloseFullScreenIcon } from '@/components/icons/CloseFullscreenIcon'
import { CartsIcon } from '@/components/icons/CartsIcon'
import { OffersIcon } from '@/components/icons/OffersIcon'
import { ServiceIcon } from '@/components/icons/ServiceIcon'
import {
  ENABLE_MY_OFFERS_DASHBOARD_UI,
  ENABLE_RENEWALS_DASHBOARD_UI,
  ENABLE_REPAIRS_DASHBOARD_UI,
} from '@/constants'

export type MENU_ITEM_PROPS = {
  text: string
  path: string
  tabs?: string[]
  isNew?: boolean | undefined
  clickAction?: boolean | undefined
  icon: (props: IconProps) => JSX.Element
}

export const MENU_LINKS = [
  {
    text: 'dashboard.menu.general',
    items: [
      {
        text: 'dashboard.menu.miners',
        path: '/dashboard/miners/hosted',
        icon: MinersIcon,
      },
      // {
      //   text: 'dashboard.menu.compass-wallet',
      //   path: '/dashboard/balance', // // #DEV-4798 . Set to empty string when compass wallet is ready
      //   icon: CompassWalletIcon,
      //   isNew: false,
      //   clickAction: true,
      // },
      {
        text: 'dashboard.menu.billing',
        path: '/dashboard/billing',
        icon: PaymentsIcon,
      },
      ENABLE_MY_OFFERS_DASHBOARD_UI === 'true' && {
        text: 'dashboard.menu.my-offers',
        path: '/dashboard/my-offers',
        tabs: ['/dashboard/prepaid-credits'],
        icon: OffersIcon,
      },
      ENABLE_RENEWALS_DASHBOARD_UI === 'true' && {
        text: 'dashboard.menu.renewals',
        path: '/dashboard/renewals',
        icon: AutoRenewIcon,
      },
      ENABLE_REPAIRS_DASHBOARD_UI === 'true' && {
        text: 'dashboard.menu.services',
        path: '/dashboard/services',
        icon: ServiceIcon,
      },
      {
        text: 'dashboard.menu.settings',
        path: '/dashboard/settings',
        icon: SettingsIcon,
      },
      {
        text: 'dashboard.menu.pool-management',
        path: '/dashboard/mining-pool',
        icon: CloseFullScreenIcon,
      },
    ].filter(Boolean) as MENU_ITEM_PROPS[],
  },
  {
    text: 'dashboard.menu.start-mining',
    items: [
      {
        text: 'dashboard.menu.buy-miners',
        path: '/hardware',
        icon: CartsIcon,
      },
    ],
  },
  {
    text: 'dashboard.menu.default-pool',
    items: [],
  },
]

export const setMiningPoolImage = (miningPool: string): string => {
  let image: string
  switch (miningPool?.toString().toLowerCase()) {
    case 'antpool':
      image = '/img/mining-pool-icons/Antminer.png'
      break
    case 'binance pool':
      image = '/img/mining-pool-icons/BinancePool.png'
      break
    case 'btc.com':
      image = '/img/mining-pool-icons/BTC.com.png'
      break
    case 'emcd':
      image = '/img/mining-pool-icons/EMCD.png'
      break
    case 'f2pool':
      image = '/img/mining-pool-icons/F2Pool.png'
      break
    case 'foundry':
      image = '/img/mining-pool-icons/Foundry.png'
      break
    case 'luxor pool':
      image = '/img/mining-pool-icons/LuxorPool.png'
      break
    case 'nicehash':
      image = '/img/mining-pool-icons/NiceHash.png'
      break
    case 'novablock':
      image = '/img/mining-pool-icons/NovaBlock.png'
      break
    case 'poolin':
      image = '/img/mining-pool-icons/Poolin.png'
      break
    case 'prohashing':
      image = '/img/mining-pool-icons/Prohashing.png'
      break
    case 'sbi crypto':
      image = '/img/mining-pool-icons/SBIcrypto.png'
      break
    case 'slush pool':
      image = '/img/mining-pool-icons/SlushPool.png'
      break
    case 'ukrpool':
      image = '/img/mining-pool-icons/UKRpool.png'
      break
    case 'variant16':
      image = '/img/mining-pool-icons/Variant16.png'
      break
    case 'viabtc':
      image = '/img/mining-pool-icons/ViaBTC.png'
      break
    case 'zulupool':
      image = '/img/mining-pool-icons/ZuluPool.png'
      break
    default:
      image = '/img/mining-pool-icons/OtherPool.png'
  }
  return image
}
