import { Icon, IconProps } from '@chakra-ui/react'

export function ChatBubbleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 48 48" fill="none" {...props}>
      <circle cx={24} cy={24} r={24} fill="#EDEEF0" />
      <path
        d="M12.5 27.2751H13.5V26.896L13.2487 26.6122L12.5 27.2751ZM12.5 38H11.5V39.9207L13.0735 38.8192L12.5 38ZM21.3521 31.8035L21.5008 30.8146L21.1059 30.7552L20.7786 30.9843L21.3521 31.8035ZM37 21C37 26.3116 31.4178 31 24 31V33C32.0462 33 39 27.8386 39 21H37ZM24 11C31.4178 11 37 15.6884 37 21H39C39 14.1614 32.0462 9 24 9V11ZM11 21C11 15.6884 16.5822 11 24 11V9C15.9538 9 9 14.1614 9 21H11ZM13.2487 26.6122C11.8121 24.9899 11 23.0563 11 21H9C9 23.6087 10.0359 26.0009 11.7513 27.9381L13.2487 26.6122ZM13.5 38V27.2751H11.5V38H13.5ZM20.7786 30.9843L11.9265 37.1808L13.0735 38.8192L21.9256 32.6228L20.7786 30.9843ZM24 31C23.1444 31 22.3089 30.9362 21.5008 30.8146L21.2034 32.7924C22.1101 32.9288 23.045 33 24 33V31Z"
        fill="currentColor"
      />
      <circle cx="19.5" cy="21.5" r="1.5" fill="currentColor" />
      <circle cx="24.5" cy="21.5" r="1.5" fill="currentColor" />
      <circle cx="29.5" cy="21.5" r="1.5" fill="currentColor" />
    </Icon>
  )
}
