import React, { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Text,
  Icon,
  HStack,
  VStack,
  Link,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { useQuery, useLazyQuery } from '@apollo/client'
import { getOutstandingInvoicesCount } from '@/api/billing'
import { getUpcomingRenewalsCount } from '@/api/renewals'
import {
  ENABLE_RENEWALS_DASHBOARD_UI,
  ENABLE_REPAIRS_DASHBOARD_UI
} from '@/constants'
import { MENU_LINKS, MENU_ITEM_PROPS } from './constants'
import MiningPoolMenuItem from '@/features/mining-pool/MiningPoolMenuItem'
import CompassWalletModal from '@/features/dashboard/CompassWalletModal'
import { isUserKycQuery } from '@/api/dashboard'
import { KycBox } from '@/features/dashboard/KycBox'
import { getRepairsCount } from '@/api/services'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Menu = ({ onClose }: { onClose?: () => void }) => {
  const t = useTranslations()
  const router = useRouter()
  const activeColor = useColorModeValue('gray.150', 'gray.250')
  const { data: invoicesCountData } = useQuery(getOutstandingInvoicesCount, {
    fetchPolicy: 'no-cache',
  })
  let unpaidInvoices: number

  if (invoicesCountData?.allInvoicesWithLineItems) {
    unpaidInvoices = invoicesCountData.allInvoicesWithLineItems.totalCount
  } else {
    unpaidInvoices = 0
  }

  const [upcomingRenewals, setUpcomingRenewals] = useState(0)
  const [repairs, setRepairs] = useState(0)
  const [getUpcomingRenewalsQuery] = useLazyQuery(getUpcomingRenewalsCount, {
    fetchPolicy: 'no-cache',
  })
  const [getRepairsQuery] = useLazyQuery(getRepairsCount, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (ENABLE_RENEWALS_DASHBOARD_UI === 'true') {
      getUpcomingRenewalsQuery({
        onCompleted: (data) => {
          setUpcomingRenewals(data?.upcomingRenewals.totalCount || 0)
        },
      })
    }

    if (ENABLE_REPAIRS_DASHBOARD_UI === 'true') {
      getRepairsQuery({
        onCompleted: (data) => {
          setRepairs(data?.totalRequestedRepairsByAccount || 0)
        },
      })
    }
  }, [])

  const { data: dataKyc } = useQuery(isUserKycQuery, {
    fetchPolicy: 'cache-first',
  })

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()

  const getFormattedText = (text: string): string => {
    return text.toLowerCase().replace(' ', '-')
  }

  // #DEV-4798 . Uncomment when compass-wallet is ready
  /* const handleLinkClick = (item: MENU_ITEM_PROPS): void => {
    const formattedPathName = getFormattedText(t(item.text))
    switch (formattedPathName) {
      case 'compass-wallet':
        if (router.pathname !== `/dashboard/${formattedPathName}`) {
          if (dataKyc?.amIKyced) {
            router.push('/dashboard/balance', undefined, { shallow: true })
          } else {
            onOpen()
          }
        }
        break

      default:
        return
    }
  } */

  return (
    <>
      <Box mt={{ md: '30px', xl: '28px' }}>
        {MENU_LINKS.map((menu, i) => {
          return (
            <Box
              key={`menu-group-${i}`}
              mb={{ base: '73px', md: '13px', xl: '35px' }}
            >
              <Text
                fontSize={'sm'}
                lineHeight={{ base: '16px', md: '11px', xl: '16px' }}
                textTransform="uppercase"
                mb={{ base: '16px', md: '6.8px', xl: '16px' }}
              >
                {t(menu.text)}
              </Text>
              {menu.text === 'dashboard.menu.default-pool' && (
                <MiningPoolMenuItem />
              )}
              <VStack alignItems="flex-start" width="100%">
                {menu.items.map((item: MENU_ITEM_PROPS, j: number) => {
                  const isActive =
                    (item?.path && router.asPath?.includes(item?.path)) ||
                    router.pathname ===
                      `/dashboard/${getFormattedText(t(item.text))}` ||
                    item.tabs?.some((tab) => router.asPath.includes(tab))
                  const isPayments = item.text === 'dashboard.menu.billing'
                  const isRenewals = item.text === 'dashboard.menu.renewals'
                  const isServices = item.text === 'dashboard.menu.services'
                  return (
                    <NextLink key={`menu-item-${j}`} href={item.path} passHref>
                      <Link
                        width="100%"
                        _hover={{
                          textDecoration: 'none',
                        }}
                        onClick={() => {
                          if (onClose) {
                            onClose()
                          }
                          // #DEV-4798 . Uncomment when compass-wallet is ready
                          // if (item.clickAction) {
                          //   handleLinkClick(item)
                          // }
                        }}
                      >
                        <HStack
                          cursor="pointer"
                          width="100%"
                          padding={{ base: '18px', md: '12px', xl: '16px' }}
                          bg={isActive ? activeColor : 'transparent'}
                          borderRadius="6px"
                          _hover={{
                            bg: activeColor,
                            '& .icon': {
                              color: 'brand.600',
                            },
                          }}
                        >
                          <Icon
                            as={item.icon}
                            className="icon"
                            color={isActive ? 'brand.600' : 'gray.850'}
                            width={'20px'}
                            height={'20px'}
                          />
                          <Text color="gray.850" fontSize={'md'}>
                            {t(item.text)}
                          </Text>
                          {isPayments &&
                            unpaidInvoices &&
                            unpaidInvoices !== 0 && (
                              <Text
                                background="brand.600"
                                borderRadius="full"
                                color="white"
                                fontSize="xs"
                                px={2}
                              >
                                {unpaidInvoices}
                              </Text>
                            )}
                          {isRenewals &&
                            upcomingRenewals &&
                            upcomingRenewals !== 0 && (
                              <Text
                                background="brand.600"
                                borderRadius="full"
                                color="white"
                                fontSize="xs"
                                px={2}
                              >
                                {upcomingRenewals}
                              </Text>
                            )}
                          {isServices &&
                            repairs &&
                            repairs !== 0 && (
                              <Text
                                background="brand.600"
                                borderRadius="full"
                                color="white"
                                fontSize="xs"
                                px={2}
                              >
                                {repairs}
                              </Text>
                            )}
                          {item.isNew && (
                            <Center
                              bg="gradient.orange"
                              borderRadius="30px"
                              height={'19px'}
                              width={'40px'}
                              color="white"
                              fontWeight="500"
                              textTransform="uppercase"
                              fontSize={'10px'}
                            >
                              {t('dashboard.menu.new')}
                            </Center>
                          )}
                        </HStack>
                      </Link>
                    </NextLink>
                  )
                })}
              </VStack>
              <CompassWalletModal isOpen={isOpen} onCloseModal={onCloseModal} />
            </Box>
          )
        })}
        <KycBox onClose={onClose} />
      </Box>
    </>
  )
}
