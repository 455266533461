import { gql } from '@apollo/client'

export const getUpcomingRenewalsCount = gql`
  query getUpcomingRenewalsCount {
    upcomingRenewals: renewalUpcoming(
      filter: { isLocked: { equalTo: false } }
    ) {
      totalCount
    }
  }
`

export const getRenewalDefaultSelection = gql`
  query getRenewalDefaultSelection {
    renewalAccountDefaultSelection {
      nodes {
        accountEligibleRenewals
        accountIneligibleRenewals
      }
    }
  }
`

export const getRenewalUpcoming = gql`
  query renewalUpcoming {
    upcomingRenewals: renewalUpcoming(
      filter: { isLocked: { equalTo: false } }
    ) {
      nodes {
        minerId
        machineName
        hostingFacility
        shortId
        serial
        algorithm
        hashrate
        power
        efficiency
        currentMonthlyFees
        termEnd
        newMonthlyFees
        newRenewalStart
        isEligible
        renewalSelection
        isLocked
      }
    }
    finalizedRenewals: renewalUpcoming(
      filter: { isLocked: { equalTo: true } }
    ) {
      nodes {
        minerId
        machineName
        hostingFacility
        shortId
        serial
        algorithm
        hashrate
        power
        efficiency
        currentMonthlyFees
        termEnd
        newMonthlyFees
        newRenewalStart
        isEligible
        renewalSelection
        isLocked
      }
    }
  }
`

export const updateRenewalsDefaultSelection = gql`
  mutation renewalUpdateAccountDefaultSelection(
    $eligibleOption: MinerContractRenewals!
    $ineligibleOption: MinerContractRenewals!
  ) {
    renewalUpdateAccountDefaultSelection(
      input: {
        eligibleOption: $eligibleOption
        ineligibleOption: $ineligibleOption
      }
    ) {
      clientMutationId
      boolean
    }
  }
`

export const updateRenewalByMinerId = gql`
  mutation renewalUpdateMinerSelectedOption(
    $minerId: UUID!
    $renewalOption: MinerContractRenewals!
  ) {
    renewalUpdateMinerSelectedOption(
      input: { minerId: $minerId, renewalOption: $renewalOption }
    ) {
      clientMutationId
      boolean
    }
  }
`
