import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import {
  Stack,
  Menu,
  MenuList,
  MenuItem,
  useColorModeValue,
} from '@chakra-ui/react'

import { CONTACT_SUPPORT } from '@/constants'
import { AccountMenuButton } from '@/features/auth/AccountMenuButton'
import { AuthButton } from '@/features/auth/AuthButton'
import { CartButton } from '@/features/checkout/CartButton'
import { AccountMenuItem } from '@/features/auth/AccountMenuItem'
import { NavColorMode } from '@/features/auth/NavColorMode'

type Props = {
  menuCloseFn: () => void
  renderCartButton: boolean
}

export function NavbarButtons({ menuCloseFn, renderCartButton = true }: Props) {
  const [isSignInPage, setIsSignInPage] = useState(false)
  const router = useRouter()
  const t = useTranslations()

  useEffect(() => {
    const isSignIn = router.pathname === '/sign-in'
    setIsSignInPage(isSignIn)
  }, [router])

  return (
    <Stack
      flex={{ base: 1, md: 0 }}
      justify={{ base: 'items-center', lg: 'flex-end' }}
      direction={{ base: 'column', sm: 'row' }}
      spacing={3}
      fontSize="16px"
    >
      <Menu onClose={menuCloseFn} closeOnSelect={true}>
        {!isSignInPage && <AccountMenuButton onClick={menuCloseFn} />}
        <MenuList>
          <AccountMenuItem
            href="/dashboard/miners/hosted"
            label={t('buttons.dashboard')}
          />
          <AccountMenuItem
            href="/dashboard/billing"
            label={t('dashboard.menu.billing')}
          />
          <AccountMenuItem
            href="/dashboard/settings"
            label={t('dashboard.menu.settings')}
          />
          <AccountMenuItem
            href={CONTACT_SUPPORT}
            label={t('common.submit-a-ticket')}
          />
          <MenuItem>
            <AuthButton
              as="a"
              variant="transparent"
              fontSize="md"
              width="100%"
              justifyContent="flex-start"
              padding={0}
              height={7}
              colorScheme={useColorModeValue('gray', 'alwaysWhite')}
            />
          </MenuItem>
        </MenuList>
      </Menu>
      <NavColorMode />
      {renderCartButton && <CartButton />}
    </Stack>
  )
}
